import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    createPaymentSession(request) {
        return services.http.post(`/v1.0/checkout/create-session`, request);
    },
    createEventPaymentSession(request) {
        return services.http.post(`/v1.0/checkout/event-license`, request);
    },
    createEventProductSession(request) {
        return services.http.post(`/v1.0/checkout/event-product`, request);
    },
    createOrgPaymentSession(request) {
        return services.http.post(`/v1.0/checkout/org-license`, request);
    },
    createOrgProductSession(request) {
        return services.http.post(`/v1.0/checkout/org-product`, request);
    },
    createOrgUpdatePaymentMethodSession(id) {
        return services.http.post(`/v1.0/checkout/orgs/${id}/paymentmethod`, {});
    },
    getSessionStatus(id) {
        return services.http.get(`/v1.0/checkout/sessions/${id}`);
    },
    getEventStatus(id) {
        return services.http.get(`/v1.0/checkout/events/${id}`);
    },
    getOrgStatus(id) {
        return services.http.get(`/v1.0/checkout/orgs/${id}/status`);
    },
    getOrgPaymentPortalLink(id) {
        return services.http.get(`/v1.0/checkout/orgs/${id}/portal`);
    },
    cancelOrgSubscription(id) {
        return services.http.post(`/v1.0/checkout/orgs/${id}/cancel`);
    },
    resumeOrgSubscription(id) {
        return services.http.post(`/v1.0/checkout/orgs/${id}/resume`);
    },

    createRegistrationSession(eventId, code, view, request) {
        return services.http.post(`/v1.0/checkout/events/${eventId}/reg/${code}?view=${view||''}`, request);
    },
    getRegistrationSessionStatus(eventId, code) {
        return services.http.get(`/v1.0/checkout/events/${eventId}/reg/${code}`);
    },
    createEventUpgradeSession(eventId, request) {
        return services.http.post(`/v1.0/checkout/events/${eventId}/upgrade`, request);
    },

    startConnectFlow() {
        return services.http.post(`/v1.0/checkout/connect/start`, {});
    },
    getConnectStatus() {
        return services.http.get(`/v1.0/checkout/connect/status`);
    },
}